body {
  margin: 0;
  font-family: source-sans-pro, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.swal2-popup .swal2-styled:focus {
  box-shadow: none !important;
}

.jodit-toolbar-button button {
  margin-bottom: 0px;
}

.mui-data-table tbody tr:last-child {
  background-color: #f0f0f0;
  /* Cambiar el color de fondo */
  font-weight: bold;
  /* Cambiar la fuente a negrita */
}

.css-1x51dt5-MuiInputBase-input-MuiInput-input {
  font: inherit;
  letter-spacing: inherit;
  color: currentColor;
  padding: 4px 0 5px;
  border: 0;
  box-sizing: content-box;
  background: none;
  height: 1.4375em;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0;
  width: 100% !important;
  -webkit-animation-name: mui-auto-fill-cancel;
  animation-name: mui-auto-fill-cancel;
  -webkit-animation-duration: 10ms;
  animation-duration: 10ms;
}

.leaflet-container {
  border-radius: 0em !important;
}

.css-f78se9-MuiButtonBase-root-MuiButton-root {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  width: 100%;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  min-width: 64px;
  -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.dragging {
  border: 1px dashed gray;
  margin-bottom: 8px
}

.dragginonpressed:hover {
  background-color: aqua;
}

.label-container {
  display: flex;
  align-items: center;
}


.asterisk-container {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: red;
  margin-left: 5px;
}

.asterisk {
  color: white;
  font-size: 12px;
  font-weight: bold;
}

input[type="file"] {
  padding: 4px;
  margin: -4px;
  position: relative;
  outline: none;

  &::file-selector-button {
    border-radius: 4px;
    padding: 0 16px;
    height: 40px;
    cursor: pointer;
    background-color: white;
    border: 1px solid rgba(#000, 0.16);
    box-shadow: 0px 1px 0px rgba(#000, 0.05);
    margin-right: 16px;

    width: 132px;
    color: transparent;

    @supports (-moz-appearance: none) {
      color: var(--primary-color);
    }

    &:hover {
      background-color: #f3f4f6;
    }

    &:active {
      background-color: #e5e7eb;
    }
  }

  &::before {
    position: absolute;
    pointer-events: none;
    top: 14px;
    left: 16px;
    height: 20px;
    width: 20px;
    content: "";
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%230964B0'%3E%3Cpath d='M18 15v3H6v-3H4v3c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2v-3h-2zM7 9l1.41 1.41L11 7.83V16h2V7.83l2.59 2.58L17 9l-5-5-5 5z'/%3E%3C/svg%3E");
  }

  &::after {
    position: absolute;
    pointer-events: none;
    top: 16px;
    left: 40px;
    color: var(--primary-color);
    content: "Upload File";
  }

  &:focus-within::file-selector-button,
  &:focus::file-selector-button {
    outline: 2px solid var(--primary-color);
    outline-offset: 2px;
  }
}

.fc-day-today {
  background: #e3f2fd !important;
  border: none !important;
}

.fc-toolbar-title {
  text-transform: capitalize;
  color: rgb(54, 65, 82);
  font-family: Roboto, sans-serif;
}

.fc-col-header-cell {
  background-color: #f8fafc;
  color: rgb(54, 65, 82);
  height: 3em;
  align-items: center;
  font-size: 20px;
  line-height: 3rem;
}

/* styles.css (o tu archivo de estilos personalizado) */

/* Estilos para los botones en el headerToolbar */
.fc-header-toolbar .fc-button {
  background-color: #fff;
  color: #333;
  border: 1px solid #94ccfb;
  border-radius: 6px;
  padding: 6px 12px;
  margin-right: 5px;
}

.numberformat {
  background-color: white;
  height: 3.2em;
  border: 2px solid #CDCDCD;
  border-radius: 6px;
  padding-left: 6px;
}
